/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import React, { useCallback } from 'react';
import type { FC, MouseEvent } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useQuery } from 'react-apollo';

import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import EditorAddIcon from '@atlaskit/icon/core/add';
import AddIcon from '@atlaskit/icon/utility/add';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { canPerformSpaceOperation, OperationTypes } from '@confluence/entity-operations';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import type {
	ContextualCreateContentButtonSpaceOperationsQuery as ContextualCreateContentButtonSpaceOperationsQuery$data,
	ContextualCreateContentButtonSpaceOperationsQueryVariables as ContextualCreateContentButtonSpaceOperationsQuery$variables,
} from './__types__/ContextualCreateContentButtonSpaceOperationsQuery';

const i18n = defineMessages({
	simpleCreateLabel: {
		id: 'create-blank-fabric-page.contextual-create.button.simple-create-label',
		description: 'Label for a button that creates content in the current space',
		defaultMessage: 'Create',
	},
	simpleCreateLabelForTitle: {
		id: 'create-blank-fabric-page.contextual-create.button.simple-create-label-for-title',
		description: 'Aria label for a button that creates content in the current space',
		defaultMessage: 'Create child content for {pageTitle}',
	},
});

const canCreateContent = (data: ContextualCreateContentButtonSpaceOperationsQuery$data) => {
	return canPerformSpaceOperation({
		operationCheckResult: data,
		operation: OperationTypes.CREATE,
		contentType: 'page',
	});
};

export const contextualCreateContentIconSVGColor = (isOpen?: boolean): string => {
	return isOpen ? token('color.icon.selected') : token('color.icon.subtle');
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-unsafe-style-overrides -- To migrate as part of go/ui-styling-standard
const ContextualCreateButton = styled(IconButton)<{
	isRootCreateButton: boolean;
	isOpen?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& svg': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) => contextualCreateContentIconSVGColor(props.isOpen),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:hover svg': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) => (props.isRootCreateButton ? token('color.icon') : 'inherit'),
	},
});

type ContextualCreateButtonProps = {
	triggerProps: TriggerProps;
	onClick: (event: MouseEvent<HTMLElement>) => void;
	isContextualCreateFocused: boolean | undefined;
	spaceKey: string;
	rootCreateButtonStyling: boolean;
	displayTitle?: string;
};

export const ContextualCreateContentButton: FC<ContextualCreateButtonProps> = ({
	triggerProps,
	onClick,
	isContextualCreateFocused,
	spaceKey,
	rootCreateButtonStyling,
	displayTitle,
}) => {
	const intl = useIntl();
	const { data, loading, error } = useQuery<
		ContextualCreateContentButtonSpaceOperationsQuery$data,
		ContextualCreateContentButtonSpaceOperationsQuery$variables
	>(
		gql`
			query ContextualCreateContentButtonSpaceOperationsQuery($spaceKey: String!) {
				space(key: $spaceKey) {
					id
					operations {
						operation
						targetType
					}
				}
			}
		`,
		{
			variables: { spaceKey },
		},
	);
	const isNav4Enabled = useIsNav4Enabled();

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			onClick(e);
		},
		[onClick],
	);

	if (error) {
		return <ErrorDisplay error={error} />;
	}

	const canCreate = data && canCreateContent(data);
	if (loading || !canCreate) {
		return null;
	}

	return (
		<SpotlightTarget name="pagetree-createcontent">
			<Tooltip
				content={<FormattedMessage {...i18n.simpleCreateLabel} />}
				position="top"
				hideTooltipOnMouseDown
			>
				<ContextualCreateButton
					{...triggerProps}
					id="contextual-create-content-button"
					appearance="subtle"
					icon={() =>
						isNav4Enabled ? (
							<AddIcon label={intl.formatMessage(i18n.simpleCreateLabel)} />
						) : (
							<EditorAddIcon label={intl.formatMessage(i18n.simpleCreateLabel)} />
						)
					}
					label={
						displayTitle
							? intl.formatMessage(i18n.simpleCreateLabelForTitle, {
									pageTitle: displayTitle,
								})
							: intl.formatMessage(i18n.simpleCreateLabel)
					}
					onClick={handleClick}
					testId="contextual-create-content-button"
					isSelected={isContextualCreateFocused}
					isOpen={isContextualCreateFocused}
					isRootCreateButton={rootCreateButtonStyling}
					// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
					isDisabled={Boolean(process.env.REACT_SSR)}
					spacing="compact"
				/>
			</Tooltip>
		</SpotlightTarget>
	);
};
