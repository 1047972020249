import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import AddIcon from '@atlaskit/icon/utility/add';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

type CreateContentMenuItemProps = {
	onClick: () => void;
	href?: string;
};

const addIcon = <AddIcon label="" color="currentColor" />;

export const CreateContentMenuItem = ({ onClick, href }: CreateContentMenuItemProps) => {
	if (href) {
		return (
			<MenuLinkItem onClick={onClick} elemBefore={addIcon} href={href}>
				<FormattedMessage {...i18n.create} />
			</MenuLinkItem>
		);
	}

	return (
		<MenuButtonItem onClick={onClick} elemBefore={addIcon}>
			<FormattedMessage {...i18n.create} />
		</MenuButtonItem>
	);
};

const i18n = defineMessages({
	create: {
		id: 'create-blank-fabric-page.create-content-menu-item.create',
		description:
			'Label for a button that creates content in the space the user is currently viewing',
		defaultMessage: 'Create',
	},
});
